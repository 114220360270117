var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Typography } from "@material-ui/core";
import { useEffect } from "react";
import { useTranslation } from "../../i18n";
export function PageTitle(_a) {
    var { title } = _a, props = __rest(_a, ["title"]);
    const { t } = useTranslation(["navigation"]);
    const defaultTitle = t("title");
    useEffect(() => {
        const titles = [title, defaultTitle];
        // eslint-disable-next-line fp/no-mutation
        document.title = titles.filter((x) => x === null || x === void 0 ? void 0 : x.length).join(" | ");
        return () => {
            // eslint-disable-next-line fp/no-mutation
            document.title = defaultTitle;
        };
    }, [title, defaultTitle]);
    return _jsx(Typography, Object.assign({}, props, { children: title }));
}
