import { NumberSchema, ValidationError } from "yup";

export function getThresholdErrorMessage(
  validator: NumberSchema,
  inputValue?: string | number,
): string | undefined {
  try {
    validator.validateSync(inputValue ? Number(inputValue) : undefined);
  } catch (error) {
    if (error instanceof ValidationError) {
      return error.errors[0];
    }
  }

  return undefined;
}
