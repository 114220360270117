import { Snackbar, useTranslation, useApolloClient } from "@lumar/shared";
import {
  GetReportNotificationsQueryVariables,
  ThresholdType,
  useUpdateReportRulesAndThresholdsMutation,
} from "../../graphql";
import { useSnackbar } from "notistack";
import { ExtendedThresholdSettings, MonitorNotification } from "../types";
import { getErrorMessage } from "../../_common/utils/getErrorMessage";
import { updateReportNotificationsInCache } from "../utils/updateReportNotificationsInCache";
import { useClearReportNotificationCache } from "../utils/useClearNotificationCache";
import {
  EditBaseRuleAndThresholdDialog,
  SaveRuleElement,
} from "./EditBaseRuleAndThresholdDialog";
import { getThresholdErrorMessage } from "../utils/thresholdValidation";
import { useUrlThresholdSchema } from "../../alerts/_common/utils/useValidationSchema";

export interface EditReportRuleAndThresholdDialogProps {
  isOpen: boolean;
  onClose: () => void;
  notification: MonitorNotification;
  queryVariables?: GetReportNotificationsQueryVariables;
  isAdjustment: boolean;
}

export function EditReportRuleAndThresholdDialog({
  isOpen,
  onClose,
  notification,
  queryVariables,
  isAdjustment,
}: EditReportRuleAndThresholdDialogProps): JSX.Element {
  const apollo = useApolloClient();
  const clearCache = useClearReportNotificationCache();

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation([
    "alerts",
    "common",
    "filterPredicates",
    "notifications",
  ]);

  const thresholdValidator = useUrlThresholdSchema(t);

  const [updateRulesAndThresholds, { loading }] =
    useUpdateReportRulesAndThresholdsMutation({
      refetchQueries: [
        "GetNotifications",
        "ReportNotificationsRequiresManualApprovalCount",
        "NotificationTotalCounts",
        "GetReportNotificationsCount",
      ],
      awaitRefetchQueries: true,
    });

  async function handleSave({
    severity,
    thresholdPredicate,
    absoluteThreshold,
    automaticThresholdAcceptanceWhenTestResultIsBetter:
      thresholdAcceptanceBetter,
    automaticThresholdAcceptanceWhenTestResultIsWorse: thresholdAcceptanceWorse,
  }: SaveRuleElement): Promise<void> {
    try {
      await updateRulesAndThresholds({
        variables: {
          rulesAndThresholds: {
            severity,
            thresholdPredicate,
            absoluteThreshold: absoluteThreshold,
            testId: notification.test?.id,
            thresholdType: ThresholdType.Absolute,
            automaticThresholdAcceptanceWhenTestResultIsWorse:
              thresholdAcceptanceWorse,
            automaticThresholdAcceptanceWhenTestResultIsBetter:
              thresholdAcceptanceBetter,
          },
        },
      });
      handleRuleAndThresholdUpdate({
        thresholdPredicate,
        absoluteThreshold: absoluteThreshold,
        id: notification.test?.id,
        severity,
        automaticThresholdAcceptanceWhenTestResultIsWorse:
          thresholdAcceptanceWorse,
        automaticThresholdAcceptanceWhenTestResultIsBetter:
          thresholdAcceptanceBetter,
      });

      enqueueSnackbar(
        <Snackbar
          title={t("notificationsPage.thresholdUpdatedSuccessfully")}
          variant="success"
        />,
      );
    } catch (e) {
      enqueueSnackbar(
        <Snackbar
          title={t("notificationsPage.thresholdFailedToUpdate", {
            message: getErrorMessage(e),
          })}
          variant="error"
        />,
      );
    }

    onClose();
  }

  function handleRuleAndThresholdUpdate(test: ExtendedThresholdSettings): void {
    if (
      test.absoluteThreshold !== notification.absoluteThreshold ||
      test.automaticThresholdAcceptanceWhenTestResultIsBetter !==
        notification.automaticThresholdAcceptanceWhenTestResultIsBetter ||
      test.automaticThresholdAcceptanceWhenTestResultIsWorse !==
        notification.automaticThresholdAcceptanceWhenTestResultIsWorse ||
      test.thresholdPredicate !== test.thresholdPredicate
    ) {
      if (isAdjustment) clearCache();
      if (queryVariables)
        updateReportNotificationsInCache(apollo.cache, queryVariables, (nt) => {
          if (notification.id !== nt.id) return {};
          return {
            test: {
              ...nt.test,
              absoluteThreshold: test.absoluteThreshold,
              automaticThresholdAcceptanceWhenTestResultIsBetter:
                test.automaticThresholdAcceptanceWhenTestResultIsBetter,
              automaticThresholdAcceptanceWhenTestResultIsWorse:
                test.automaticThresholdAcceptanceWhenTestResultIsWorse,
              thresholdPredicate: test.thresholdPredicate,
            },
          };
        });
    }
  }

  return (
    <EditBaseRuleAndThresholdDialog
      handleSave={handleSave}
      notification={notification}
      isOpen={isOpen}
      onClose={onClose}
      validate={(n) => getThresholdErrorMessage(thresholdValidator, n)}
      loading={loading}
    />
  );
}
