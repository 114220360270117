/* eslint-disable fp/no-this */
import { useTranslation } from "@lumar/shared";
import { TFunction } from "i18next";
import { filter, includes } from "lodash";
import isEmail from "validator/lib/isEmail";
import isUrl from "validator/lib/isURL";
import * as Yup from "yup";
import { ReportOption } from "../../../_common/utils/constants";
import { MAX_ABSOLUTE_THRESHOLD_URLS } from "./constants";
import { RuleAndThreshold } from "./types";

export function useValidationSchema(): unknown {
  const { t } = useTranslation(["alerts", "msTeamsNotifications"]);

  return Yup.object().shape({
    reportRulesAndThresholds: Yup.array().of(
      Yup.object()
        .shape({
          severity: Yup.string().required(),
          report: Yup.object()
            .shape({
              id: Yup.string(),
              name: Yup.string(),
              code: Yup.string(),
              totalSign: Yup.number(),
            })
            .required(t("reportValidationMessage"))
            .nullable(),
          threshold: Yup.string().required(),
          urls: useUrlThresholdSchema(t),
        })
        .test("unique", t("uniqueValidation"), (value, context) => {
          const element = value as RuleAndThreshold<ReportOption>;
          const sameReportList: RuleAndThreshold<ReportOption>[] =
            context.parent.filter(
              (item: RuleAndThreshold<ReportOption>) =>
                item.report.code === value.report?.code,
            );
          if (sameReportList.length <= 1) return true;
          const isUnique =
            sameReportList.filter((e) => e.segment?.id === element.segment?.id)
              .length < 2;

          return isUnique;
        })
        .test("valid", t("useableWithSegment"), (value) => {
          const element = value as RuleAndThreshold<ReportOption>;
          if (!element.report.useableWithSegments && element.segment?.id)
            return false;
          return true;
        }),
    ),
    categoryRulesAndThresholds: Yup.array().of(
      Yup.object()
        .shape({
          severity: Yup.string().required(),
          report: Yup.object()
            .shape({
              id: Yup.string(),
              name: Yup.string(),
              code: Yup.string(),
              totalSign: Yup.number(),
            })
            .required(t("categoryValidationMessage"))
            .nullable(),
          threshold: Yup.string().required(),
          urls: useHealthScoreThresholdSchema(t),
        })
        .test("unique", t("uniqueCategoryValidation"), (value, context) => {
          const element = value as RuleAndThreshold<ReportOption>;
          const sameReportList: RuleAndThreshold<ReportOption>[] =
            context.parent.filter(
              (item: RuleAndThreshold<ReportOption>) =>
                item.report.code === value.report?.code,
            );
          if (sameReportList.length <= 1) return true;
          const isUnique =
            sameReportList.filter((e) => e.segment?.id === element.segment?.id)
              .length < 2;

          return isUnique;
        }),
    ),
    emailAlerts: Yup.array()
      .of(
        Yup.string().test(
          "email",
          t("invalidEmail"),
          (email) => !email || isEmail(email),
        ),
      )
      .test("unique", function (array) {
        if (!array) return true;

        const dup = filter(array, (val, i, iteratee) =>
          includes(iteratee, val, i + 1),
        );
        if (!dup?.length) return true;

        const errors = array
          .map((el, index) =>
            dup.includes(el)
              ? this.createError({
                  path: `${this.path}.${index}`,
                  message: t("duplicatedEmail"),
                })
              : <Yup.ValidationError>{ type: "none" },
          )
          .filter((e) => e.type !== "none");
        return new Yup.ValidationError(errors);
      }),
    slackWebhooks: Yup.array()
      .of(
        Yup.string().test(
          "isURL",
          t("invalidDomain"),
          (value) =>
            value !== undefined &&
            isUrl(value, {
              protocols: ["http", "https"],
              require_protocol: true,
            }),
        ),
      )
      .test("unique", function (array) {
        if (!array) return true;

        const dup = filter(array, (val, i, iteratee) =>
          includes(iteratee, val, i + 1),
        );
        if (!dup?.length) return true;

        const errors = array
          .map((el, index) =>
            dup.includes(el)
              ? this.createError({
                  path: `${this.path}.${index}`,
                  message: t("duplicatedSlack"),
                })
              : <Yup.ValidationError>{ type: "none" },
          )
          .filter((e) => e.type !== "none");
        return new Yup.ValidationError(errors);
      }),
    microsoftTeamsWebhooks: Yup.array()
      .of(
        Yup.string().test(
          "isURL",
          t("msTeamsNotifications:validationErrors.invalidWebhook"),
          (value) =>
            value !== undefined &&
            isUrl(value, {
              protocols: ["http", "https"],
              require_protocol: true,
            }),
        ),
      )
      .test("unique", function (array) {
        if (!array) return true;

        const dup = filter(array, (val, i, iteratee) =>
          includes(iteratee, val, i + 1),
        );
        if (!dup?.length) return true;

        const errors = array
          .map((el, index) =>
            dup.includes(el)
              ? this.createError({
                  path: `${this.path}.${index}`,
                  message: t(
                    "msTeamsNotifications:validationErrors.duplicateWebhook",
                  ),
                })
              : <Yup.ValidationError>{ type: "none" },
          )
          .filter((e) => e.type !== "none");
        return new Yup.ValidationError(errors);
      })
      .max(100, t("msTeamsNotifications:validationErrors.maximumNumber")),
  });
}

export function useUrlThresholdSchema(
  t: TFunction<"alerts", "msTeamsNotifications">,
): Yup.NumberSchema {
  return Yup.number()
    .integer(t("notAnInteger"))
    .required(t("urlsRequiredValidationMessage"))
    .min(0, t("minUrlsValidationMessage"))
    .max(
      MAX_ABSOLUTE_THRESHOLD_URLS,
      t("maxUrlsValidationMessage", {
        count: MAX_ABSOLUTE_THRESHOLD_URLS,
      }),
    );
}

export function useHealthScoreThresholdSchema(
  t: TFunction<"alerts", "msTeamsNotifications">,
): Yup.NumberSchema {
  return Yup.number()
    .required(t("urlsRequiredValidationMessage"))
    .integer(t("notAnInteger"))
    .min(1, t("minScoreValidationMessage"))
    .max(100, t("maxScoreValidationMessage"));
}
