import { Typography, Button, useTranslation, PageTitle } from "@lumar/shared";
import { ProjectAutocomplete } from "./ProjectAutocomplete";
import { makeStyles } from "@material-ui/core";
import { useHistory } from "react-router";
import { AlertOption } from "../../../_common/utils/constants";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    height: 63,
    padding: theme.spacing(0, 3),
  },
  cancelButton: {
    marginLeft: "auto",
    marginRight: 12,
  },
  mainContentContainer: {
    margin: "50px auto",
    maxWidth: 360,
  },
  titleText: {
    display: "block",
    marginBottom: 15,
    fontSize: theme.typography.pxToRem(18),
  },
  bodyText: {
    marginBottom: 22,
    display: "block",
    lineHeight: theme.typography.pxToRem(14.5),
    color: theme.palette.grey[500],
  },
  createNewAlertTitle: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 600,
  },
}));

interface Props {
  selectedProject: AlertOption | null;
  onProjectChange: (newProject: AlertOption | null) => void;
}

export function ChooseProjectView({
  selectedProject,
  onProjectChange,
}: Props): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation(["common", "alerts"]);
  const history = useHistory();

  const handleCancel = (): void => {
    history.goBack();
  };

  return (
    <>
      <div className={classes.container}>
        <PageTitle
          title={t("alerts:createNewAlert")}
          variant="h6"
          className={classes.createNewAlertTitle}
        />

        <Button
          className={classes.cancelButton}
          variant="outlined"
          size="large"
          onClick={handleCancel}
          data-pendo="cancel-create-alert"
        >
          {t("common:cancel")}
        </Button>
        <Button variant="contained" color="primary" size="large" disabled>
          {t("common:saveAndClose")}
        </Button>
      </div>
      <div className={classes.mainContentContainer}>
        <Typography variant="h6SemiBold" className={classes.titleText}>
          {t("alerts:chooseProjectViewTitle")}
        </Typography>
        <Typography variant="caption" className={classes.bodyText}>
          {t("alerts:chooseProjectViewDescription")}
        </Typography>
        <ProjectAutocomplete
          value={selectedProject}
          onChange={onProjectChange}
        />
      </div>
    </>
  );
}
