import {
  Button,
  Typography,
  CogSolid,
  PlusSolid,
  Breadcrumbs,
  useTranslation,
  insertIf,
  SelectableButtonGroup,
  useSession,
} from "@lumar/shared";
import { useTheme } from "@material-ui/core";
import { TopNav } from "../navigation/TopNav";
import { HideFromInsufficientRole } from "../_common/components/HideFromInsufficientRole";
import { useMonitorRoutes } from "../_common/routing/useMonitorRoutes";

import { useHealthScoreEnabled } from "../_common/hooks/useHealthScoreEnabled";
import { ReportNotificationsTab } from "./ReportNotificationsTab";
import { HealthScoreNotificationsTab } from "./HealthScoreNotificationsTab";
import { useEffect, useState } from "react";

export function NotificationsPage(): JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation(["alerts", "notifications", "navigation"]);
  const { alertsPage, createAlertPage } = useMonitorRoutes();
  const [currentTab, setCurrentTab] = useState<string>("report");
  const { hasFeatureFlagEnabled } = useSession();

  const defaultTitle = t("navigation:title");
  const title = t("notificationsCenter");

  useEffect(() => {
    const titles = [title, defaultTitle];
    // eslint-disable-next-line fp/no-mutation
    document.title = titles.filter((x) => x?.length).join(" | ");
    return () => {
      // eslint-disable-next-line fp/no-mutation
      document.title = defaultTitle;
    };
  }, [title, defaultTitle]);

  const hasHealthScores =
    useHealthScoreEnabled() && hasFeatureFlagEnabled("health-score-alerts");

  const tabs = [
    {
      label: t("notifications:reportNotifications"),
      value: "report",
      tab: <ReportNotificationsTab />,
    },
    ...insertIf(hasHealthScores, {
      label: t("notifications:healthscoreNotifications"),
      value: "healthscore",
      tab: <HealthScoreNotificationsTab />,
    }),
  ];
  return (
    <>
      <TopNav includeNotificationsMenu={false}>
        <Breadcrumbs
          breadcrumbItems={[
            {
              label: "Home",
              link: "/",
            },
            {
              label: t("notifications"),
            },
          ]}
        />
      </TopNav>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: theme.spacing(1),
        }}
      >
        <Typography variant="h1" style={{ marginRight: 8, fontWeight: 600 }}>
          {tabs.length === 1 ? (
            title
          ) : (
            <SelectableButtonGroup
              value={currentTab}
              onValueChange={(value) => {
                setCurrentTab(value);
              }}
              options={tabs}
            />
          )}
        </Typography>

        <Button
          style={{ marginLeft: "auto" }}
          variant="outlined"
          size="large"
          startIcon={<CogSolid />}
          data-pendo="notifications-page-manage-notifications-button"
          onClick={() => {
            alertsPage.visit();
          }}
        >
          {t("manage")}
        </Button>
        <HideFromInsufficientRole>
          <Button
            variant="contained"
            color="primary"
            size="large"
            data-pendo="notifications-page-manage-create-alert-button"
            startIcon={<PlusSolid />}
            style={{ marginLeft: 14 }}
            onClick={() => {
              createAlertPage.visit();
            }}
          >
            {t("createNewAlert")}
          </Button>
        </HideFromInsufficientRole>
      </div>
      {tabs.find((e) => e.value === currentTab)?.tab ?? (
        <ReportNotificationsTab />
      )}
    </>
  );
}
