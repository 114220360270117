import {
  ArrowLeft,
  ArrowLeftOutlined,
  ArrowRight,
  Button,
  EmptyState,
  getRawAccountId,
  PageTitle,
  Snackbar,
  TextField,
  useTranslation,
} from "@lumar/shared";
import {
  Backdrop,
  CircularProgress,
  IconButton,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import clsx from "clsx";
import { Formik, FormikProps } from "formik";
import { useSnackbar } from "notistack";
import { useMemo, useRef, useState } from "react";
import { InView } from "react-intersection-observer";
import { useHistory } from "react-router-dom";
import { assert } from "../../../_common/assert";
import { HideFromInsufficientRole } from "../../../_common/components/HideFromInsufficientRole";
import { RedirectIfInsufficientRole } from "../../../_common/components/RedirectIfInsufficientRole";
import { useHealthScoreEnabled } from "../../../_common/hooks/useHealthScoreEnabled";
import { AbsoluteURLs } from "../../../_common/routing/absoluteURLs";
import { useGenericParams } from "../../../_common/routing/useGenericParams";
import { useMonitorRoutes } from "../../../_common/routing/useMonitorRoutes";
import { useURLSearchParams } from "../../../_common/routing/useURLSearchParams";
import { useGetAccountViewCountQuery } from "../../../graphql";
import { TemplatesView } from "../../components/create-from-template/TemplatesView";
import { getCodeValuesInArray } from "../../components/create-from-template/utils/utility";
import {
  MAX_NAME_LENGTH,
  MIN_NAME_LENGTH,
} from "../../dashboards/account-overview/name-dialog/DashboardNameDialog";
import { View } from "../types";
import { AccountViewsCountProgressBar } from "./components/AccountViewsCountProgressBar";
import { AccountViewsTooltip } from "./components/AccountViewsTooltip";
import { AllViewsPanel } from "./components/AllViewsPanel";
import { SelectedViewsPanel } from "./components/SelectedViewsPanel";
import { filterViewList, GetViewListFilter } from "./utils/filterViewList";
import { useCreateOrUpdateCollection } from "./utils/useCreateOrUpdateCollection";
import { useGetViewListAccumulator } from "./utils/useGetViewListAccumulator";
import { useGetViewsForBoardAccumulator } from "./utils/useGetViewsForBoardAccumulator";

export const TITLE_HEIGHT = 63;
const NAME_CONTAINER_HEIGHT = 90;
const BASIC_POLLING_RATE = 20000;

enum Steps {
  Settings,
  Template,
}

export function BoardSettings(): JSX.Element {
  const classes = useStyles();
  const { accountId, collectionId } = useGenericParams();
  assert(accountId);

  const { dashboardPage } = useMonitorRoutes();

  const { t } = useTranslation(["board", "common"]);
  const history = useHistory<{ prevPath: string } | undefined>();
  const urlSearchParams = useURLSearchParams();
  const urlAction = urlSearchParams.get("action");
  const theme = useTheme();
  const formRef = useRef<FormikProps<Record<string, boolean>>>(null);
  const savedValues = useRef<Record<string, boolean>>({});

  const useHealthScore = useHealthScoreEnabled();

  const lastButtonTitle =
    urlAction !== undefined
      ? t("common:saveAndClose")
      : t("common:createBoard");

  const { enqueueSnackbar } = useSnackbar();

  const [headerVisible, setHeaderVisible] = useState<boolean>(true);
  const [isCountVisible, setCountVisible] = useState<boolean>(true);
  const [name, setName] = useState<string | undefined>("Board");
  const [step, setStep] = useState<Steps>(Steps.Settings);
  const viewLimits = useRef<{
    maxViewsCount: number;
    viewsCount: number;
  }>({
    maxViewsCount: -1,
    viewsCount: 0,
  });

  const [searchTerms, setSearchTerms] = useState<GetViewListFilter>({});
  const [searchTermsSelected, setSearchTermsSelected] =
    useState<GetViewListFilter>({});

  const [selected, setSelected] = useState<View[]>([]);

  const isEditing = Boolean(collectionId);
  const isCloning = Boolean(collectionId) && Boolean(urlAction === "clone");

  const buttonTitle =
    step === Steps.Settings && !isEditing ? t("next") : lastButtonTitle;

  const notEditTitle = isCloning
    ? t("board:duplicateSettings")
    : t("board:title");
  const titleFromAction =
    urlAction === "edit" ? t("board:editSettings") : notEditTitle;

  const title =
    step === Steps.Settings
      ? (titleFromAction ?? t("title"))
      : t("templateTitle");

  const shouldShowSegmentsInAvailableViews =
    searchTerms.segmentNameSearchTherm !== null;

  const shouldShowSegmentsInSelectedViews =
    searchTermsSelected.segmentNameSearchTherm !== null;

  const {
    data: accountViewList,
    segmentList,
    loading: loadingData,
    error,
  } = useGetViewListAccumulator(false);

  const polling = useRef<number>(BASIC_POLLING_RATE);

  const {
    data: viewCountData,
    stopPolling,
    startPolling,
  } = useGetAccountViewCountQuery({
    variables: {
      accountId,
    },
    fetchPolicy: "no-cache",
    pollInterval: BASIC_POLLING_RATE,
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      // on network error the polling interval is set to 5 minutes.
      // If error occurs again, polling is stopped totally.
      if (error.networkError) {
        if (polling.current > BASIC_POLLING_RATE) return stopPolling();
        polling.current = BASIC_POLLING_RATE * 15;
        return startPolling(polling.current);
      }
      // for any other error, polling is stopped.
      stopPolling();
    },
    onCompleted: () => {
      // If network problem is solved it turns back to 1 minute
      if (polling.current > BASIC_POLLING_RATE) {
        polling.current = BASIC_POLLING_RATE;
        startPolling(polling.current);
      }
    },
  });

  if (viewCountData)
    viewLimits.current = {
      maxViewsCount: viewCountData.getAccount?.maxCount ?? 10000,
      viewsCount: viewCountData.getAccount?.viewCount ?? 0,
    };

  const filteredViewList = useMemo(
    () => filterViewList(accountViewList, searchTerms, selected),
    [accountViewList, searchTerms, selected],
  );

  const filteredSelected = useMemo(
    () => filterViewList(selected, searchTermsSelected),
    [selected, searchTermsSelected],
  );

  const selectedSegmentList = useMemo(() => {
    return selected.reduce((acumulator, value) => {
      const found =
        Boolean(acumulator.find((e) => e === value.segmentName)) ||
        !Boolean(value.segmentName);
      return !Boolean(found)
        ? [...acumulator, value.segmentName ?? ""]
        : acumulator;
    }, [] as string[]);
  }, [selected]);

  const {
    loading: boardLoading,
    error: boardError,
    data: boardData,
  } = useGetViewsForBoardAccumulator(!Boolean(collectionId), (data) => {
    const name = data?.getCustomDashboardCollection?.name;
    setName(isCloning ? "Copy of " + name : name);
    setSelected(
      data?.getCustomDashboardCollection?.customViews.edges.map(
        ({ node: e }) =>
          ({
            id: e.id,
            projectId: e.project.id,
            segmentId: e.segment?.id,
            projectName: e.project.name,
            segmentName: e.segment?.name,
            primaryDomain: e.project.primaryDomain,
            scheduleFrequency: e.project.schedule?.scheduleFrequency,
          }) as View,
      ) ?? [],
    );
  });

  const viewsCount = isCloning
    ? viewLimits.current.viewsCount
    : viewLimits.current.viewsCount - (boardData?.viewsTotalCount ?? 0);
  const maxViewsCount = viewLimits.current.maxViewsCount;
  const totalViewCount = viewsCount + selected.length;

  const handleCancel = (): void => {
    history.goBack();
  };

  const isNameTooLong = (name?.trim().length ?? 0) > MAX_NAME_LENGTH;
  const isNameTooShort = (name?.trim().length ?? 0) < MIN_NAME_LENGTH;
  const isInvalidAccount =
    getRawAccountId(boardData?.accountId ?? "") !==
      getRawAccountId(accountId) && isCloning;

  const { loading: updateIsRunning, createOrUpdateCollection } =
    useCreateOrUpdateCollection({
      onCompleted: (id) => {
        if (id) {
          enqueueSnackbar(
            <Snackbar
              variant="success"
              title={
                id === collectionId
                  ? t("board:updatedSuccessfully")
                  : t("board:createdSuccessfully")
              }
            />,
          );
          dashboardPage.visit({ accountId, collectionId: id });
        }
      },
    });

  const loading = loadingData || boardLoading || updateIsRunning;

  const showBackButton = step === Steps.Template && !headerVisible;

  const buttons = (
    <>
      {step === Steps.Settings && !isCountVisible && (
        <AccountViewsCountProgressBar
          count={viewsCount}
          current={selected.length}
          total={maxViewsCount}
        />
      )}
      <HideFromInsufficientRole>
        {showBackButton ? (
          <Button
            variant="outlined"
            size="large"
            type="submit"
            data-pendo="board-settings-back-button"
            data-testid="board-settings-back-button"
            disabled={loading}
            onClick={() => {
              setStep(Steps.Settings);
            }}
            startIcon={<ArrowLeft />}
          >
            {t("common:previous")}
          </Button>
        ) : undefined}
        <Button
          className={classes.cancelButton}
          variant="outlined"
          size="large"
          type="button"
          onClick={handleCancel}
          data-pendo="cancel-create-or-update-board-settings"
          data-testid="cancel-create-or-update-board-settings"
          disabled={loading}
        >
          {t("common:cancel")}
        </Button>
        <AccountViewsTooltip
          show={
            totalViewCount > maxViewsCount
              ? "limit"
              : isInvalidAccount
                ? "account"
                : null
          }
          arrow={false}
          leaveDelay={1000}
        >
          <div>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              data-pendo="create-save-and-close-board-settings"
              data-testid="create-or-update-boards-settings-button"
              disabled={
                !Boolean(selected.length) ||
                isNameTooLong ||
                isNameTooShort ||
                totalViewCount > maxViewsCount ||
                loading ||
                isInvalidAccount
              }
              endIcon={
                step === Steps.Settings && !isEditing ? (
                  <ArrowRight />
                ) : undefined
              }
              onClick={() => {
                if (isEditing) {
                  createOrUpdateCollection({
                    boardViews: selected,
                    name,
                    originalViews: boardData?.views ?? [],
                    templateCodes: [],
                    cloning: isCloning,
                  });
                } else {
                  switch (step) {
                    case Steps.Settings:
                      setStep(Steps.Template);
                      break;
                    case Steps.Template:
                      formRef.current?.handleSubmit();
                      break;
                  }
                }
              }}
            >
              {buttonTitle}
            </Button>
          </div>
        </AccountViewsTooltip>
      </HideFromInsufficientRole>
    </>
  );

  const isEmpty = !accountViewList.length && !loading;

  async function handleSubmit(values: Record<string, boolean>): Promise<void> {
    const templateCodes = getCodeValuesInArray(values);
    createOrUpdateCollection({
      boardViews: selected,
      name,
      originalViews: boardData?.views ?? [],
      templateCodes,
      cloning: isCloning,
    });
  }

  return (
    <RedirectIfInsufficientRole to={dashboardPage.getUrl()}>
      <Backdrop className={classes.backdrop} open={updateIsRunning}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100vh",
          overflowY: "auto",
          flexFlow: "column",
        }}
      >
        <InView
          as="div"
          className={classes.headerContainer}
          onChange={(inView) => {
            setHeaderVisible(inView);
          }}
        >
          <div className={classes.titleContainer}>
            {step === Steps.Template ? (
              <IconButton
                className={classes.backButton}
                onClick={() => setStep(Steps.Settings)}
                title={t("back")}
                data-pendo="board-settings-back-button"
                data-testid="board-settings-back-button"
              >
                <ArrowLeftOutlined />
              </IconButton>
            ) : undefined}
            <PageTitle title={title} variant="h6" className={classes.title} />

            {buttons}
          </div>
        </InView>
        <div className={classes.container}>
          {step === Steps.Settings ? (
            <>
              <div className={classes.nameContainer}>
                <TextField
                  value={loading ? "" : (name ?? "Board")}
                  title="Name"
                  style={{ maxWidth: 460 }}
                  label="Board name"
                  error={isNameTooLong || isNameTooShort}
                  onChange={(event) => setName(event.target.value)}
                  helperText={
                    isNameTooLong
                      ? t("board:nameTooLong")
                      : isNameTooShort
                        ? t("board:nameTooShort")
                        : undefined
                  }
                  data-testid="board-settings-name-field"
                />
                <div
                  style={{
                    display: "flex",
                    marginLeft: "auto",
                    alignItems: "center",
                    alignSelf: "start",
                    marginTop: "auto",
                    marginBottom: "auto",
                  }}
                >
                  <InView
                    onChange={(visible) => setCountVisible(visible)}
                    rootMargin="-6px 0px 0px 0px"
                  >
                    <AccountViewsCountProgressBar
                      count={viewsCount}
                      current={selected.length}
                      total={maxViewsCount}
                    />
                  </InView>
                </div>
              </div>
              <div className={classes.viewsContainer}>
                {!isEmpty ? (
                  <>
                    <AllViewsPanel
                      limit={maxViewsCount - viewsCount}
                      disabled={totalViewCount >= maxViewsCount}
                      filteredViewList={filteredViewList}
                      viewList={selected}
                      error={error}
                      filters={searchTerms}
                      loading={loading}
                      shouldShowSegments={shouldShowSegmentsInAvailableViews}
                      segmentList={segmentList}
                      onFilterChange={(filter) => setSearchTerms(filter)}
                      onViewListChange={(list) => setSelected(list)}
                    />
                    <SelectedViewsPanel
                      filteredViewList={filteredSelected}
                      viewList={selected}
                      error={boardError}
                      filters={searchTermsSelected}
                      loading={loading}
                      shouldShowSegments={shouldShowSegmentsInSelectedViews}
                      segmentList={selectedSegmentList}
                      onFilterChange={(filter) =>
                        setSearchTermsSelected(filter)
                      }
                      onViewListChange={(list) => setSelected(list)}
                    />
                  </>
                ) : (
                  <EmptyState
                    className={classes.horcenter}
                    title={t("noActiveViews")}
                    description={t("noActiveViewsDescription")}
                    actions={[
                      {
                        type: "button",
                        title: t("goToAnalysisApp"),
                        onClick: () => {
                          window.location.href = window.location.href =
                            AbsoluteURLs.EXTERNAL__AnalyzeProjectsList.getUrl({
                              accountId,
                            });
                        },
                      },
                    ]}
                  />
                )}
              </div>
            </>
          ) : undefined}
          {step === Steps.Template ? (
            <Formik
              initialValues={savedValues.current}
              enableReinitialize
              onSubmit={handleSubmit}
              validateOnChange={false}
              innerRef={formRef}
            >
              {({ values }) => {
                savedValues.current = values;
                return (
                  <div
                    style={{
                      marginLeft: theme.spacing(4),
                      marginRight: theme.spacing(4),
                    }}
                  >
                    <TemplatesView
                      values={values}
                      useHealthScore={useHealthScore}
                    />
                  </div>
                );
              }}
            </Formik>
          ) : undefined}
          {!headerVisible ? (
            <div
              className={clsx(classes.headerContainer, classes.stickyBottom)}
            >
              <div className={classes.buttonContainer}>{buttons}</div>
            </div>
          ) : undefined}
        </div>
      </div>
    </RedirectIfInsufficientRole>
  );
}

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    height: TITLE_HEIGHT,
    maxHeight: TITLE_HEIGHT,
    minHeight: TITLE_HEIGHT,
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    height: TITLE_HEIGHT,
    padding: theme.spacing(0, 3),
    width: "100%",
  },
  title: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 600,
  },
  container: {
    display: "flex",
    maxHeight: `calc(100vh + ${NAME_CONTAINER_HEIGHT + TITLE_HEIGHT}px)`,
    minHeight: `calc(100vh - ${TITLE_HEIGHT}px)`,
    flexFlow: "column",
  },
  nameContainer: {
    flexGrow: 0,
    padding: theme.spacing(0, 3),
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    height: NAME_CONTAINER_HEIGHT,
    width: "100%",
    display: "flex",
    alignItems: "center",
    flex: 1,
    minHeight: NAME_CONTAINER_HEIGHT,
  },
  cancelButton: {
    marginRight: 12,
    marginLeft: "auto",
  },
  stickyBottom: {
    position: "sticky",
    zIndex: theme.zIndex.snackbar - 2,
    bottom: 0,
    backgroundColor: "#D1D5DB4C",
    backdropFilter: "blur(8px)",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    height: TITLE_HEIGHT,
    padding: theme.spacing(0, 3),
  },
  viewsContainer: {
    padding: theme.spacing(0, 3),
    display: "flex",
    flexFlow: "row",
    flex: 1,
    height: "100%",
  },
  flex: {
    flex: 1,
  },
  horcenter: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  backButton: {
    borderRadius: 6,
    marginRight: 8,
    padding: 3,
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
    "& svg": {
      height: 18,
      width: 18,
      color: theme.palette.grey[700],
    },
  },
  backdrop: {
    zIndex: theme.zIndex.snackbar - 1,
  },
}));
